<template>
  <div class="flex flex-col p-5 max-w-sm mx-auto">
    <router-link
      to="/"
      class="mb-10 text-2xl"
    >
      wünsch<b>mir</b>.de
    </router-link>
    <div class="font-bold text-4xl mb-10">
      404
    </div>
    <div class="text-xl mb-4">
      Diese Seite existiert leider nicht.
    </div>
    <div class="mb-2">
      Hast du den Link richtig geschrieben?
    </div>
    <div>
      Könnte es sein dass du deine Wunschliste noch nicht gespeichert hast?
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotFound',
}
</script>
