<template>
  <footer class="flex mt-10 bg-white bg-opacity-80 p-4 rounded-lg">
    <router-link
      to="/"
      active-class="activeLink"
      class="link"
    >
      wünsch<b>mir</b>.de
    </router-link>
    <router-link
      to="/about"
      active-class="activeLink"
      class="flex-1 text-right underline hover:text-green-500"
    >
      Über uns
    </router-link>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
  .link {
    @apply flex-1 text-left underline hover:text-green-500
  }
  .activeLink {
    @apply font-bold hover:text-black no-underline
  }
</style>