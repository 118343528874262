<template>
  <div
    ref="wishElement"
    class="bg-white bg-opacity-80 rounded-lg my-2 flex-col overflow-hidden"
  >
    <img
      v-if="image"
      :src="image"
      class="object-cover max-h-52 w-full object-top"
    >
    <div class="flex-col p-4">
      <div class="flex">
        <div class="inline-flex items-center border-black border-r pr-2 mr-2">
          <input
            v-model="abgehakt"       
            type="checkbox"
            :disabled="previewMode"
            class="rounded text-green-500 text-xl w-5 h-5 cursor-pointer hover:text-red-500"
            @click="showModal = true"
          >
        </div>
        <div class="flex-col w-full text-left">
          <a
            v-if="wish.link"
            :href="wish.link"
            target="_blank"
            rel="noopener noreferrer"
            class="underline text-green-700 hover:text-green-500"
          >{{ wish.titel }}</a>
          <div v-else>
            {{ wish.titel }}
          </div>
        </div>
        <button
          v-if="wish.detailed_description" 
          @click="showDetailsModal = true"
        >
          ℹ️
        </button>
      </div>
      <div
        v-if="abgehaktVon"
        class=" mt-2 bg-green-500 text-white rounded-lg w-full text-right px-2 py-1"
      >
        Danke {{ abgehaktVon }}
      </div>
    </div>
    <Modal
      v-if="abgehakt"
      :show="showModal"
      :wish-title="wish.titel"
      :wish-checked-by="abgehaktVon"
      :list-owner-name="listOwnerName"
      :mode="!alreadyCheckedByAnother ? 'present' : 'alreadyCheckedByAnother'"
      :loading="modalLoading"
      :unknown-error="modalUnknownError"
      @closeModal="closeModal"
      @confirmClicked="abhaken"
    >
      <template v-if="!alreadyCheckedByAnother">
        <input
          v-model="giftersName"
          :class="{ 'border-red-500': formErrors }"
          class="rounded-md max-w-full w-full"
          type="text"
          placeholder="Dein Name"
          autofocus
          @keypress.enter="abhaken"
        >
        <p
          v-if="formErrors"
          class="text-sm text-red-500"
        >
          Dein Name muss eingegeben werden
        </p>
      </template>
    </Modal>
    <Modal
      v-if="!abgehakt"
      :show="showModal"
      :unknown-error="modalUnknownError"
      :wish-title="wish.titel"
      :list-owner-name="listOwnerName"
      :users-name="giftersName"
      :gifters-name="abgehaktVon"
      mode="revert"
      :loading="modalLoading"
      @closeModal="closeModal"
      @confirmClicked="abhaken"
    />
    <Suspense>
      <DetailsModal
        v-if="wish.detailed_description"
        :show="showDetailsModal"
        :wish-title="wish.titel"
        :description="wish.detailed_description"
        @closeModal="closeDetailsModal"
      />
    </Suspense>
  </div>
</template>

<script>
import { Directus } from '@directus/sdk';
import Modal from '@/components/Modal'
import DetailsModal from '@/components/DetailsModal'

const directus = new Directus('https://data.wunschmir.de');

export default {
  name: 'Wish',
  components:{
    Modal,
    DetailsModal,
  },
  props:{
    wish:{
      type: Object,
      required: true
    },
    listOwnerName:{
      type: String,
      required: true
    },
    previewMode: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
      return {
        abgehakt: null,
        abgehaktVon: null,
        image: null,
        giftersName: '',
        showModal: false,
        showDetailsModal: false,
        formErrors: false,
        modalLoading: false,
        alreadyCheckedByAnother: false,
        modalUnknownError: false
      }
  },
  watch: {
    previewMode(value) {
      if (!value) {
        this.abgehakt = this.wish.abgehakt
        this.abgehaktVon = this.wish.abgehakt_von
      } else {
        this.abgehakt = false
      }
    }
  },
  async created() {
    if (!this.previewMode) {
      this.abgehakt = this.wish.abgehakt
      this.abgehaktVon = this.wish.abgehakt_von
      const savedGiftersName = window.localStorage.getItem('giftersName')
      if (savedGiftersName) {
        this.giftersName = savedGiftersName
      }
    }
  },
  mounted() {
    if (this.wish.Bild) {
      this.image = `https://data.wunschmir.de/assets/${this.wish.Bild}?width=${this.$refs.wishElement.clientWidth}&withoutEnlargement=true&quality=80&format=webp`
    }
  },
  methods: {
    closeModal(keepCheckedStatus){
      if (!keepCheckedStatus) {
        this.abgehakt = !this.abgehakt;
      }
      this.showModal = false;
      this.formErrors = false;
    },
    closeDetailsModal(){
      this.showDetailsModal = false;
    },
    async abhaken(){
      if (!this.giftersName && this.abgehakt) {
        this.formErrors = true;
      } else {
        this.modalLoading = true
        try {
          const { abgehakt, abgehakt_von } = await directus.items('Wunsch').readOne(this.wish.id)

          // save name for next usage
          if (this.giftersName) {
            window.localStorage.setItem('giftersName', this.giftersName)
          }

          // wish was already checked by other person
          if (this.abgehakt === true && abgehakt === true) {
            this.alreadyCheckedByAnother = true
            this.abgehaktVon = abgehakt_von
          } else {
            await directus.items('Wunsch')
              .updateOne(
                this.wish.id, 
                { 
                  abgehakt: this.abgehakt, 
                  abgehakt_von: this.abgehakt ? this.giftersName : null, 
                  abgehakt_am: this.abgehakt ? new Date(Date.now()).toISOString() : null
                }
              )

            this.showModal= false;
            this.abgehaktVon = this.abgehakt ? this.giftersName : null;
          }
        } catch (error) {
          this.modalUnknownError = true
          console.error("error in updating wish", error)
        } finally {
          this.modalLoading = false
        }
      }
    }
  }
}
</script>
