<template>
  <div
    v-show="show"
    class="w-full h-full bg-black bg-opacity-60 fixed z-20 overflow-auto left-0 top-0 backdrop-filter backdrop-blur-sm"
    @click="closeModal"
  >
    <div
      class="flex items-center justify-center h-screen"
    >
      <div
        class="w-full max-w-xl bg-white border-green-500 border-2 rounded p-4 relative mx-4"
        @click.stop
      >
        <div v-if="loading">
          ...Loading...
        </div>
        <div v-else-if="unknownError">
          💥 Leider ist ein interner Fehler aufgetreten 💥
          <div class="mt-2 text-base">
            <span>Bitte versuche die Seite neu zu laden, falls das nicht klappt schreibe uns eine Nachricht an </span>
            <a
              class="underline"
              href="mailto:admin@wünschmir.de"
            >
              admin@wünschmir.de
            </a>
          </div>
        </div>
        <template v-else>
          <button
            type="button"
            class="absolute -right-2 -top-4 bg-green-500 rounded-full flex justify-center items-center text-white font-extrabold text-xl cursor-pointer hover:bg-green-300"
            style="height:30px;width:30px;"
            @click="closeModal"
          >
            <span>X</span>
          </button>
          <h1
            class="text-lg font-bold"
          >
            <template v-if="mode === 'present'">
              Schenken
            </template>
            <template v-else-if="mode === 'revert'">
              Schenken zurückziehen
            </template>
            <template v-else>
              Leider zu langsam
            </template>
          </h1>
          <p class="mt-2">
            <template v-if="mode === 'present'">
              Du möchtest <b>"{{ wishTitle }}"</b> an <b>{{ listOwnerName }}</b> schenken?
            </template>
            <template v-else-if="mode === 'alreadyCheckedByAnother'">
              Tut uns leid. Der Wunsch <b>"{{ wishTitle }}"</b> wurde gerade von <b>{{ wishCheckedBy }}</b> abgehakt, du warst leider etwas zu langsam.
            </template>
            <template v-else-if="usersName && giftersName && giftersName.toLowerCase() === usersName.toLowerCase()">
              Du ({{ usersName }}) möchtest <b>"{{ wishTitle }}"</b> nicht mehr an <b>{{ listOwnerName }}</b> schenken? Der Wunsch wird wieder als verfügbar angezeigt und dein Eintrag gelöscht.
            </template>
            <template v-else>
              <b>{{ giftersName }}</b> schenkt diesen Wunsch. Du kannst den Eintrag löschen. <b>Bist du dir wirklich sicher?</b> Der Wunsch wird wieder als verfügbar angezeigt und <b>{{ giftersName }}s</b> Eintrag gelöscht.
            </template>
          </p>
          <div class="d-block my-4">
            <slot />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <template v-if="mode !== 'alreadyCheckedByAnother'">
              <button
                class="bg-gray-200 hover:bg-red-300 text-black font-bold py-2 px-4 rounded mt-4"
                @click="closeModal"
              >
                Abbrechen
              </button>
              <button
                class="bg-green-200 hover:bg-green-300 text-black font-bold py-2 px-4 rounded mt-4"
                @click="$emit('confirm-clicked')"
              >
                Ja
              </button>
            </template>
            <template v-else>
              <button
                class="bg-gray-200 hover:bg-red-300 text-black font-bold py-2 px-4 rounded mt-4 col-span-2"
                @click="closeModal"
              >
                Schließen
              </button>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Wish',
    props:{
      show: {
        required: true,
        type:Boolean
      },
      wishTitle: {
        type: String,
        required: true
      },
      listOwnerName: {
        type: String,
        required: true
      },
      wishCheckedBy: {
        type: String,
        required: false,
        default: ''
      },
      giftersName: {
        type: String,
        required: false,
        default: ''
      },
      usersName: {
        type: String,
        required: false,
        default: ''
      },
      unknownError: {
        type: Boolean,
        default: false
      },
      mode: {
        required: true,
        validator(value) {
          // The value must match one of these strings
          return ['present', 'revert', 'alreadyCheckedByAnother'].includes(value)
        }
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      closeModal() {
        this.$emit('close-modal', this.mode === 'alreadyCheckedByAnother')
      }
    }
}
</script>