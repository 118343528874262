<template>
  <div class="flex flex-col p-5 max-w-sm mx-auto">
    <div class="mb-10 text-2xl">
      wünsch<b>mir</b>.de
    </div>
    <div>
      Sende deine Geburtstags / Weihnachts / Hochzeits / Namenstags / Jahrestags...Wünsche. Erstelle eine Liste, versende sie an deine Freunde und bekomme kein Geschenk doppelt.
    </div>
    <div
      v-if="emailRegistered"
      class="text-white rounded-lg mt-10 p-5"
      :class="{ 'bg-red-400': emailRegistrationError, 'bg-green-500': !emailRegistrationError }"
    >
      <div v-if="emailRegistrationError && emailRegistrationDuplicateError">
        Deine E-Mail wurde bereits registriert. Falls du es noch nicht bestätigt hast checke deinen <b>Spam</b> Ordner.
      </div>
      <div v-else-if="emailRegistrationError">
        Leider hat die Registrierung nicht geklappt. Überprüfe nochmal deine E-Mail Adresse und versuche es erneut.
      </div>
      <div v-else>
        Danke! Du bekommst in kürze eine E-Mail mit weiteren Infos.
      </div>
    </div>
    <form
      v-if="!emailRegistered || emailRegistrationError"
      class="mt-10 text-left"
      @submit.prevent="registerUser"
    >
      <div class="mb-2 text-lg">
        Jetzt registrieren und Wunschliste erstellen
      </div>
      <div>
        <input
          v-model="email"
          class="w-full mb-2 rounded-sm"
          type="email"
          placeholder="E-Mail Adresse"
          required
          :disabled="emailInputDisabled"
        >
      </div>
      <button
        type="submit"
        class="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        :disabled="emailInputDisabled"
      >
        Registrieren
      </button>
    </form>
    <a
      href="https://data.wunschmir.de"
      class="mt-5 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Login
    </a>
    <div class="mt-10">
      Hier kannst du dir eine fertige Liste als Beispiel ansehen und ausprobieren
    </div>
    <router-link
      to="/sample-max"
      class="mt-2 w-full bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
    >
      Beispiel Liste
    </router-link>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Footer,
  },
  data() {
    return {
      email: '',
      emailInputDisabled: false,
      emailRegistered: false,
      emailRegistrationError: false,
      emailRegistrationDuplicateError: false,
    }
  },
  methods: {
    async registerUser() {
      try {
        this.emailInputDisabled = true
        this.emailRegistrationError = false
        this.emailRegistrationDuplicateError = false
        await axios.post('https://data.wunschmir.de/wishlist-user-invite', {
          email: this.email,
        })
        this.email = ''
      } catch (error) {
        console.log('error')
        console.error(error)
        this.emailRegistrationError = true
        if (error?.response?.status === 409) {
          this.emailRegistrationDuplicateError = true
        }
      } finally {
        this.emailRegistered = true
        this.emailInputDisabled = false
      }
    }
  }
}
</script>
