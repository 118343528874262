<template>
  <div
    v-show="show"
    class="w-full h-full bg-black bg-opacity-60 fixed z-20 overflow-auto left-0 top-0 backdrop-filter backdrop-blur-sm"
    @click="closeModal"
  >
    <div
      class="flex items-center justify-center h-screen"
    >
      <div
        class="w-full max-w-xl bg-white border-green-500 border-2 rounded p-4 relative mx-4"
        @click.stop
      >
        <button
          type="button"
          class="absolute -right-2 -top-4 bg-green-500 rounded-full flex justify-center items-center text-white font-extrabold text-xl cursor-pointer hover:bg-green-300"
          style="height:30px;width:30px;"
          @click="closeModal"
        >
          <span>X</span>
        </button>
        <h1
          class="text-lg font-bold"
        >
          {{ wishTitle }}
        </h1>
        <div
          class="prose mt-3"
          v-html="markdown"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { marked } from 'marked';

export default {
  name: 'Wish',
  props: {
    show: {
      required: true,
      type:Boolean
    },
    wishTitle: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  },
  async setup(props) {
    const markdown = await marked.parse(props.description)

    return {
      markdown
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal', this.mode === 'alreadyCheckedByAnother')
    },
  }
}
</script>