<template>
  <div
    :style="backgroundStyle"
    class="whishlist bg-gray-400"
  >
    <div
      class="flex flex-col p-5 max-w-sm mx-auto min-h-screen"
    >
      <div
        v-if="unknownError"
        class="rounded-lg bg-white bg-opacity-80 pt-2 pb-4 px-4 flex flex-col mb-10 text-2xl"
      >
        💥 Leider ist ein interner Fehler aufgetreten 💥
        <div class="mt-2 text-base">
          <span>Bitte versuche die Seite neu zu laden, falls das nicht klappt schreibe uns eine Nachricht an </span>
          <a
            class="underline"
            href="mailto:admin@wünschmir.de"
          >
            admin@wünschmir.de
          </a>
        </div>
      </div>
      <div v-else-if="!wishlist">
        ...Wird geladen...
      </div>
      <div
        v-else-if="wishlist?.status !== 'published' && !previewMode"
        class="rounded-lg bg-white bg-opacity-80 pt-2 pb-4 px-4 flex flex-col mb-10"
      >
        <div>
          wünsch<b>mir</b>.de
        </div>
        <div
          v-if="wishlist?.status === 'draft'"
          class="my-4"
        >
          Diese Wunschliste wird gerade erstellt und ist nicht online. Bitte versuche es später wieder.
        </div>
        <div
          v-else
          class="my-4"
        >
          Diese Wunschliste ist nicht mehr online.
        </div>
      </div>
      <template v-else>
        <div
          class="rounded-lg bg-white bg-opacity-80 pt-2 pb-4 px-4 flex flex-col mb-10"
        >
          <div class="text-left">
            wünsch<b>mir</b>.de
          </div>
          <div
            v-if="previewMode"
            class="text-yellow-600"
          >
            Du befindest dich im Vorschau-Modus.
            <span v-if="wishlist?.status === 'draft'">
              Deine Wunschliste ist ein Entwurf und <b>nicht online verfügbar</b>. Um sie online zu stellen logge dich ein und verändere den Status.
            </span>
            <span v-else-if="wishlist?.status === 'archived'">
              Deine Wunschliste ist Offline und <b>nicht online verfügbar</b>. Um sie online zu stellen logge dich ein und verändere den Status.
            </span>
            <span v-else>
              Deine Wunschliste ist online verfügbar.
              <button
                type="submit"
                class="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                @click="share"
              >
                Link teilen
              </button>
            </span>
            <div
              v-if="!$route?.query?.preview"
              class="mt-2"
            >
              Du bist nicht {{ wishlist.Name }}?
              <button
                class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                @click="endPreview"
              >
                Vorschau-Modus beenden
              </button>
            </div>
          </div>
          <div class="text-xl my-4 font-bold">
            {{ wishlist?.titel }}
          </div>
          <div>
            {{ wishlist?.Beschreibung }}
          </div>
        </div>
        <div>
          <div
            v-for="wunsch of wishlist.wuensche"
            :key="wunsch.id"
          >
            <Wish
              :wish="wunsch"
              :preview-mode="previewMode"
              :list-owner-name="wishlist.Name"
            />
          </div>
        </div>
        <button
          type="submit"
          class="bg-green-200 hover:bg-green-300 text-black font-bold py-2 px-4 rounded mt-4"
          @click="share"
        >
          Link teilen
        </button>
        <div
          v-if="wishlist.links?.length"
          class="flex flex-col mt-5 bg-white bg-opacity-80 p-4 rounded-lg items-start"
        >
          <b class="self-center">Weiterführende Links</b>
          <a
            v-for="link of wishlist.links"
            :key="`wishlistlink-${link.id}`"
            :href="link.url"
            class="underline text-blue-400 mt-1"
          >
            {{ link.label }}
          </a>
        </div>
      </template>
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Directus } from '@directus/sdk';
import Footer from '../components/Footer.vue'
import Wish from '@/components/Wish'

if (!navigator.canShare) {
  require('share-api-polyfill')
}

const directus = new Directus('https://data.wunschmir.de');

export default {
  name: 'Home',
  components: {
    Wish,
    Footer
  },
  data() {
    return {
      wishlist: null,
      backgroundStyle: '',
      backgroundUrl: null,
      previewMode: false,
      unknownError: false,
    }
  },
  async created() {
    try {
      this.wishlist = await directus.items('Wunschliste')
        .readOne(
          this.$route.params.listname,
          {
            fields: [
              'titel',
              'Name',
              'wuensche.*',
              'Beschreibung',
              'Hintergrundbild',
              'status',
              'links.*',
            ]
          }
        )

      console.log(this.wishlist)
      const previewLocalStorageKey = `${this.$route.params.listname}_preview`
      if (this.$route?.query?.preview) {
        this.previewMode = true
        window.localStorage.setItem(previewLocalStorageKey, 'true')
      } else if (window.localStorage.getItem(previewLocalStorageKey) === 'true') {
        this.previewMode = true
      }

      if (this.wishlist?.Hintergrundbild) {
        this.backgroundStyle = {
          'background-image': `url(https://data.wunschmir.de/assets/${this.wishlist.Hintergrundbild}?width=${window.innerWidth}&withoutEnlargement=true&quality=80&format=webp)`,
        }
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        this.$router.replace(`/${this.$route.params.listname}/notFound`)
      } else {
        console.error(error)
        this.unknownError = true
      }
    }
  },
  methods: {
    showBackgroundImage() {
      console.log('daaa')
      this.backgroundStyle = {
        'background-image': `url(${this.backgroundUrl})`,
      }
    },
    endPreview() {
      window.localStorage.removeItem(`${this.$route.params.listname}_preview`, 'true')
      this.previewMode = false
    },
    async share() {
      const shareObject = {
        url: `https://wunschmir.de/${this.$route.params.listname}`,
        title: this.wishlist?.titel
      }
      if (!navigator.canShare) {
        try {
          await navigator.share(
            shareObject,
            {
              copy: true,
              email: true,
              print: false,
              sms: false,
              messenger: true,
              facebook: true,
              whatsapp: true,
              twitter: true,
              linkedin: false,
              telegram: true,
              skype: false,
              pinterest: false,
              language: 'de'
            }
          )
        } catch (error) {
          if (error !== 'Invalid Params') {
            console.error(error)
          }
          // else ignore error, because the sharing functionality is working fine.
        }
      } else {
        await navigator.share(
          shareObject
        )
      }


    }
  }
}
</script>

<style scoped>
  .whishlist {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>